@use '../../style/variables.module' as v;

@mixin formGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: v.$s-8;
  padding: v.$s-1;
  width: 100%;
  label {
    font-size: v.$text-sm;
    line-height: v.$text-base;
    color: v.$color-secondary-dark-grey;
    font-weight: v.$font-bold;
    margin-bottom: v.$s-3;
  }
}

@mixin error {
  color: v.$color-primary-red !important;
  border-color: v.$color-primary-red !important;
}