@use "../../style/variables.module" as v;

.createCardSection {
    padding: v.$s-5;
    flex-direction: column;
    max-height: 100%;
    overflow-y: auto;
    z-index: 1;
}

.createCardScroll {
    overflow-y: auto;
    box-shadow: v.$box-shadow;
}

.createCardContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: calc(100% - 80px);
}

.typeOfContent {
    font-weight: bold;
    padding: v.$s-5 0;
}

.titleContainer {
    padding: 0 v.$s-5;
}

@media screen and (max-width: v.$layout-breakpoint) {
    .createCardContainer {
        grid-template-columns: 1fr;
    }
}

.formTitle {
    display: flex;
    width: 100%;
    margin: v.$s-5 auto v.$s-5;

    a {
        margin: auto;
        color: v.$color-primary-red;
    }
}

.backIcon {
    color: v.$color-secondary-dark-grey;
    margin: auto;
    cursor: pointer;

    &:hover {
        color: v.$color-secondary-light-teal;
    }
}

.closeIcon {
    margin: auto;
    color: map-get(v.$color, "primary", "shade900");
    cursor: pointer;
}

.formName {
    flex: 1;
    text-align: center;
    font-size: v.$text-2xl;
    line-height: v.$text-3xl;
    font-weight: v.$font-bold;
    color: v.$color-primary-black;
}

.inputRow {
    flex-direction: row;
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.preview {
    width: 120px;
    margin-left: 16px;
    margin-bottom: 16px;
    font-size: small;
}


.iframeContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D3D3D3;
}

.iframe {
    width: 48%;
    height: 94%;
    border: none;
}

.hidden {
    display: none;
}

.notFoundContainer {
    width: 48%;
    height: 94%;
    border: none;
    background-color: white;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 40px;
}

span {
    text-align: center;
    align-self: center;
    line-height: 24px;
    font-size: 14px;
    margin: 0 4px 0 4px;
}

.buttonPublish {
    width: 200px;
    margin-left: 10px;
}

.buttonRemove {
    width: 150px;
}

.buttonsRow {
    flex-direction: row;
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
}