* {
  padding: 0;
  margin: 0;
}

:root {
  box-sizing: border-box;
}

*,
::before,
::after {
  box-sizing: inherit;
}

body {
  font-family: 'Montserrat', 'Segoe UI', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  width: 100%;
  outline: 0;
  border-width: 0 0 1px 0;
  border-bottom: 1px solid #bcbcbc;
  -webkit-appearance: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  padding: 0 0 3px 0;
  font-size: 12px;
  line-height: 17px;
  color: #767676;
  background: #ffffff;
}

input::placeholder {
  color: #bcbcbc;
  opacity: 1; /* Firefox */
}

#root,
html,
body {
  height: 100%;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cke_toolbox span:nth-child(4),
.cke_toolbox span:nth-child(5) {
  display: none;
}

button {
  border: 0;
  font-size: 100%;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.MuiTabs-indicator {
  background-color: #00b998 !important;
}
