@use '../../../../style/variables.module' as v;
@import '../../../../style/components/page.module';

.couponStats {
  border: v.$border-width solid v.$color-secondary-dark-grey;
  border-radius: v.$border-radius;
  margin: 0 v.$s-5 v.$s-5 v.$s-5;
  padding: v.$s-4;
  display: flex;
  flex-direction: column;
  div {
    padding: v.$s-3;
    width: 100%;
    color: v.$color-secondary-dark-grey;
    font-size: v.$text-sm;
    line-height: v.$text-lg;
    font-weight: v.$font-bold;
    display: flex;
    align-items: center;
  }
}

.userStats {
  padding: v.$s-3 v.$s-3 v.$s-3 v.$s-5;
  width: 100%;
  color: v.$color-secondary-dark-grey;
  font-size: v.$text-sm;
  line-height: v.$text-lg;
  font-weight: bold;
}

.statsHighlight {
  font-size: v.$text-4xl;
  font-weight: v.$font-bold;
  color: v.$color-primary-black;
  padding-left: v.$s-3;
}

%note {
  margin: 0 v.$s-5 v.$s-3 v.$s-5;
  color: v.$color-secondary-dark-grey;
  font-size: v.$text-sm;
  line-height: v.$text-xl;
}

.statsNote {
  @extend %note;
  font-style: italic;
}

.ecooneStatsNote {
  @extend %note;
  font-style: normal;
}

.chartTitle {
  font-weight: v.$font-bold;
  font-size: v.$text-xl;
  line-height: v.$text-3xl;
  margin: v.$s-4 v.$s-5;
}

.chart {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-bottom: v.$s-5;
}

.pieChart {
  display: block;
  padding: v.$s-5 v.$s-3;
  max-width: 700px;
  width: 100%;
}

@media screen and (min-width: v.$layout-breakpoint) {
  .couponStats {
    flex-direction: row;
    div {
      justify-content: center;
      &:first-child {
        border-right: v.$border-width solid v.$color-secondary-dark-grey;
      }
    }
  }

  .chart {
    flex-direction: row;
  }

  .pieChart {
    width: 50%;
  }
}
