@use '../variables.module' as v;

.section {
  display: flex;
  flex-direction: column;
  max-width: v.$max-width;
  margin: auto;
  min-height: calc(100% - 130px);
  flex: 1;
}

.title {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: v.$s-3 v.$s-5 0;
  span {
    flex: 1;
    font-size: v.$text-2xl;
    line-height: v.$text-4xl;
    text-align: center;
    font-weight: v.$font-bold;
  }
  a {
    display: none;
    line-height: v.$text-4xl;
    background: v.$color-primary-teal;
    color: v.$color-primary-white;
    text-decoration: none;
    border-radius: v.$border-radius;
    width: 160px;
    text-align: center;
    font-size: v.$text-sm;
    &:hover {
      opacity: 0.38;
    }
  }
}

.titleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.backButton {
  color: v.$color-secondary-dark-grey;
  margin-right: v.$s-8;
  cursor: pointer;
  &:hover {
    color: v.$color-secondary-light-teal
  }
}

@media screen and (min-width: v.$layout-breakpoint) {
  .title {
    padding: v.$s-16 v.$s-5 v.$s-3 v.$s-5;
    span {
      text-align: left;
    }
    a {
      display: block;
    }
  }

  .titleContainer {
    margin: v.$s-5 0;
  }
}
