.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: calc(100% - 80px);
}

.formContainer {
  display: inline-block;
  justify-content: center;
  padding: 20px 20px;
  overflow-y: auto;
  max-height: 100%;
}

@media screen and (max-width: 800px) {
  .container {
    grid-template-columns: 1fr 0;
  }

  .formContainer {
    display: inline-block;
    justify-content: center;
    padding: 20px 20px;
    max-height: 100%;
  }
}
