/************
 ***COLORS***
 ************/
$color-primary-teal: #006064;
$color-primary-white: #FFF;
$color-primary-black: #000;
$color-primary-red: #EE5B46;
$color-primary-light-blue: #5CAACC;
$color-primary-green: #00B07B;

$color-secondary-nav-blue: #2C98F0;
$color-secondary-light-teal: #82E9DE;
$color-secondary-middle-grey: #AAAAAA;
$color-secondary-dark-grey: #767676;
$color-secondary-light-grey: #EFEFEF;

$color: (
  primary: (
    shade900: #26A68D,
    shade700: #67C1AF,
    shade500: #B7EEE3,
    shade300: #E2F8F4,
    shade100: #F3FCFA,
    black: #000000,
    white: #FFFFFF,
  ),
  secondary: (
    shade900: #986310,
    shade700: #CB8415,
    shade500: #FEA51A,
    shade300: #FEC05F,
    shade100: #FFDBA3,
    softBlack: rgba(0,0,0,0.6),
    softGrey: rgba(139, 140, 147, 0.6),
    background: #F5F5F5,
  ),
  neutral: (
    shade900: #383838,
    shade700: #747474,
    shade500: #9C9C9C,
    shade300: #D3D3D3,
    shade200: #F1F1F1,
    shade100: #FFFFFF,
  ),
  semantic: (
    success: (
      shade900: #1F7A4C,
      shade700: #29A366,
      shade500: #33CC7F,
      shade300: #A5E8C7,
      shade100: #DAF6E8,
    ),
    error: (
      shade900: #7A3333,
      shade700: #AB4747,
      shade500: #F54E4E,
      shade300: #F8A3A3,
      shade100: #FEF0F0,
    ),
    info: (
      shade900: #275D75,
      shade700: #3682A3,
      shade500: #4DB9E9,
      shade300: #A6DCF4,
      shade100: #DBF1FB,
    ),
    warning: (
      shade900: #997226,
      shade700: #CC9833,
      shade500: #FFBE40,
      shade300: #FFD88C,
      shade100: #FFF2D9,
    )
  )
);

/****************
 ***DIMENSIONS***
 ****************/
$border-width: 1px;
$border-radius: 12px;
$s-1: 0.25rem;
$s-2: 0.5rem;
$s-3: 0.75rem;
$s-4: 1rem;
$s-5: 1.25rem;
$s-6: 1.5rem;
$s-8: 2rem;
$s-10: 2.5rem;
$s-12: 3rem;
$s-16: 4rem;
$s-20: 5rem;
$s-24: 6rem;
$s-32: 8rem;
$s-40: 10rem;
$s-48: 12rem;
$s-56: 14rem;
$s-64: 16rem;
$text-2xs: 0.65rem;
$text-xs: 0.75rem;
$text-sm: 0.875rem;
$text-base: 1rem;
$text-lg: 1.125rem;
$text-xl: 1.25rem;
$text-2xl: 1.5rem;
$text-3xl: 1.875rem;
$text-4xl: 2.25rem;
$text-5xl: 3rem;
$text-6xl: 4rem;
$max-width: 1186px;
$button-width: 190px;

/************
 ***SHADOW***
 ************/
$box-shadow: 1px 5px 15px 1px rgba(0, 0, 0, 0.1);

/****************
 ***BREAKPOINT***
 ****************/
$layout-breakpoint: 800px;

/*****************
 ***FONT WEIGHT***
 *****************/
$font-hairline: 100;
$font-thin: 200;
$font-light: 300;
$font-normal: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;
$font-extrabold: 800;
$font-black: 900;

:export {
  // Export variables you want to use in javascript here
}
