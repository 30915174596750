@use '../../../../../../style/variables.module' as v;

@mixin cardContainer {
  border-radius: v.$border-radius;
  height: 664px;
  width: 360px;
  overflow: auto;
  background: #fafafa;
  box-shadow: v.$box-shadow;
  &::-webkit-scrollbar {
    display: none
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@mixin headerImage {
  height: 203px;
  width: 360px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-top-left-radius: v.$border-radius;
  border-top-right-radius: v.$border-radius;
}

@mixin contentContainer {
  padding: v.$s-5;
  display: flex;
  flex-direction: column;
}

@mixin badgeContainer {
  display: flex;
  margin-bottom: v.$s-3;
}

@mixin badge {
  font-size: v.$text-xs;
  font-weight: v.$font-bold;
  padding: v.$s-1 v.$s-3;
  border: v.$border-width solid v.$color-primary-black;
  border-radius: 8px;
}

@mixin title {
  display: flex;
  flex-wrap: wrap;
}

@mixin titleContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: v.$s-3;
  h2 {
    word-break: break-word;
  }
}

@mixin authorContainer {
  margin-bottom: v.$s-3;
  margin-top: v.$s-3;
  display: flex;
  align-items: center;
}

@mixin dateContainer {
  margin-bottom: v.$s-3;
  font-size: v.$text-xs;
}

@mixin profileImage {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: v.$s-3;
  width: 36px;
  height: 36px;
  border-radius: 100px;
  box-shadow: v.$box-shadow;
}

@mixin headerContainer {
  padding: v.$s-5;
  box-shadow: v.$box-shadow;
  border-radius: 8px;
  background: v.$color-primary-white;
  margin-bottom: v.$s-5;
}

@mixin linkIcon {
  color: v.$color-primary-white;
}

@mixin smallIcon {
  width: v.$text-xs !important;
  margin-right: v.$s-2;
}

@mixin locationContainer {
  font-size: v.$text-xs;
  display: flex;
  align-items: center;
  color: v.$color-secondary-dark-grey;
  overflow-wrap: anywhere;
  flex-wrap: wrap;
}
